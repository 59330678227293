import Container_DefaultAreaSkinComponent from '../components/Container/viewer/skinComps/DefaultAreaSkin/DefaultAreaSkin.skin';


const Container_DefaultAreaSkin = {
  component: Container_DefaultAreaSkinComponent
};


export const components = {
  ['Container_DefaultAreaSkin']: Container_DefaultAreaSkin
};

